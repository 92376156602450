<template>
  <div>
  <div class="product-list">
    <div class="product" v-for="product in products" :key="product.id" @click="openCashier(product)">
      <h3>{{ product.name }}</h3>
      <p>{{ product.description }}</p>
      <p>Price: {{ product.price }}$</p>
      <button>Get {{ product.name }}</button>
    </div>
  </div>
  <div class="flex-grow">
    <div class="flex flex-col lg:flex-row gap-3 lg:gap-7 flex-grow">
      <div id="cashier-block"></div>
    </div>
    </div>
  <!-- Cashier rendering block -->
</div>
</template>

<script>
import axios from 'axios';
import CryptoJS from 'crypto-js'; // Make sure to install it via npm
import * as api from '../api';

export default {
  name: 'ProductList',
  data() {
    return {
      products: [
        {
          id: 1, name: 'All In Forever!', description: 'Limited Offer for first 1000 Users.', price: 150, type: 'lifetime-pro',
        },
        {
          id: 2, name: 'All In', description: 'Finance bundle and PRO combined.', price: 20, type: 'all-in',
        },
        {
          id: 3, name: 'Pro', description: '3 Days Free then $15/month', price: 15, type: 'pro',
        },
        {
          id: 4, name: 'Finance Bundle', description: '3 Days Free then $15/month', price: 15, type: 'finance-bundle',
        },
      ],
    };
  },
  methods: {
    async openCashier(product) {
      console.log('Opening cashier for product:', product);
      const authToken = await this.getAuthToken(product);
      this.initCashier(authToken);
    },
    async getAuthToken(product) {
      // Sample request setup
      const request = {
        cid: '1', // custmoer id
        application_key: 'Sandbox',
        merchant_id: 'Test-Integration-Merchant',
        intent: 'payment',
        order_id: this.getOrderID(),
        timestamp: this.getCurrentTimestamp(),
        version: '1.3',
      };
      // return 'b81cf4c0ebf521f85962ed9fee0c9559';
      // cal api
      const response = await api.createPayment(request)
        .catch((error) => {
          console.error('Payment request error:', error);
        });

      console.log('Payment request response:', response);
      return response.session.auth_token;
    },
    initCashier(authToken) {
      if (!window.PraxisCashier) {
        console.error('PraxisCashier SDK is not loaded.');
        return;
      }

      const CONTAINER = document.getElementById('cashier-block');
      const MODE = 'tab';
      const LOCALE = 'en-GB';

      const cashier = window.PraxisCashier({
        auth_token: authToken,
        container: CONTAINER,
        autoclose: false,
        mode: MODE,
        locale: LOCALE,
        // src: 'https://compute.praxispay.com/login/UiyHHaNp9sk5cl40iWCTKpT3882z2y%2BQgIICyRt2%2B7WkQKIK%2BxexEZXgaLurYEhDgYAMJCssSOU062W%2FvNNOhUPHd1fCTcQ5hvwrVE14qgQ%3D?cashier_theme=default-theme',
      });

      cashier.on('resize', (data) => {
        const iframe = cashier.getCashierIframe();
        if (iframe) {
          iframe.style.height = `${data.height}px`;
        }
      });

      // Add other event handlers as needed
      cashier.on('payment_method_selected', (data) => {
        console.log('Payment method selected:', data.payment_method);
      });

      cashier.on('transaction_attempted', (data) => {
        console.log('Transaction status:', data.transaction.transaction_status);
      });

      cashier.render();
    },
    getSignature(data, merchantSecret) {
      const requestSignatureList = [
        'merchant_id',
        'application_key',
        'timestamp',
        'intent',
        'cid',
        'order_id',
      ];

      let concatenatedString = '';
      requestSignatureList.forEach((key) => {
        if (data[key] !== undefined && data[key] !== null) {
          concatenatedString += data[key];
        }
      });

      concatenatedString += merchantSecret;

      return CryptoJS.SHA384(concatenatedString).toString();
    },
    getOrderID() {
      return `order_${Math.floor(Math.random() * (100000 - 100 + 1) + 100)}`;
    },
    getCurrentTimestamp() {
      return Math.floor(Date.now() / 1000);
    },
  },
};
</script>

<style>
.product-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.product {
  background-color: #000;
  padding: 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.product:hover {
  transform: translateY(-5px);
}
</style>
