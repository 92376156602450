import { createI18n } from 'vue-i18n';
import en from './translations/en.json';
import sk from './translations/sk.json';
import es from './translations/es.json';
import cs from './translations/cs.json';
import de from './translations/de.json';
import sr from './translations/sr.json';
import config from '../config';
import * as utils from '../utils/utils';

export const translations = {
  en, sk, es, cs, de, sr,
};

// Create i18n instance with options
export const i18n = createI18n({
  locale: utils.getDefaultLanguage(),
  fallbackLocale: config.DEFAULT_LANGUAGE,
  messages: translations,
  globalInjection: true, // Ensure global injection is enabled
  legacy: false,
});
