import axios from 'axios';
import mime from 'mime';
import * as auth from './auth';

const apiBaseUrl = import.meta.env.VITE_API_BASE_URL;

console.log('apiBaseUrl', apiBaseUrl);

export const axiosInstance = axios.create({
  baseURL: apiBaseUrl,
});

/// THAURUSGURU ///
export async function createPayment(data) {
  const response = await axiosInstance.post('/api/payments/create', data);
  return response.data;
}

export async function getSignals(page, limit) {
  const queryParameters = {
    page,
    limit,
  };
  const response = await axiosInstance.get('/api/signals', { params: queryParameters });
  return response.data;
}

// Fetch all notifications for the user
export async function getNotifications() {
  const response = await axiosInstance.get('/api/notifications');
  return response.data;
}

export async function clearAllNotificationsAPI() {
  const response = await axiosInstance.post('/api/notifications/clear-all');
  return response.data;
}

// Mark a specific notification as read
export async function markNotificationAsRead(notificationId) {
  const response = await axiosInstance.post(`/api/notifications/${notificationId}/read`);
  return response.data;
}

export async function getNews(params) {
  const news = await axiosInstance.get('/api/news/headlines', { params });
  return news.data;
}

export async function getRemainingNewsAnalysis() {
  const news = await axiosInstance.get('/api/subscriptions/remaining-analysis/news');
  return news.data;
}

// request using id internal id of news
export async function getNewsDetail(newsId) {
  const news = await axiosInstance.get(`/api/news/news-detail/${newsId}`);
  return news.data;
}

export async function saveFCMToken(token) {
  const response = await axiosInstance.post('/api/users/fcm-token', { token });
  return response.data;
}

// request using id remote id of news
export async function getNewsDetailByNewsId(newsId) {
  const news = await axiosInstance.get(`/api/news/detail/${newsId}`);
  return news.data;
}

export async function getEventDetail(eventId) {
  console.log('eventId', eventId);
  const event = await axiosInstance.get(`/api/events/detail/${eventId}`);
  return event.data;
}

export async function getSignalDetail(signalId) {
  const signal = await axiosInstance.get(`/api/signals/detail/${signalId}`);
  return signal.data;
}

export async function getSentimentAnalysis(sentimentAnalysisId) {
  const sentimentAnalysis = await axiosInstance.get(`/api/analysis/sentiment/${sentimentAnalysisId}`);
  return sentimentAnalysis.data;
}

export async function getNewsAnalysis(newsId) {
  const news = await axiosInstance.get(`/api/analysis/news/${newsId}`);
  return news.data;
}

export async function getEventAnalysis(eventId) {
  const news = await axiosInstance.get(`/api/analysis/events/${eventId}`);
  return news.data;
}

export async function getTopics(search) {
  const params = { search, platform: 'web' };
  const response = await axiosInstance.get('/api/topics', { params });

  return response.data;
}

export async function getRecommendedTopics() {
  const response = await axiosInstance.get('/api/topics/recommended');
  return response.data;
}

export async function getLastVisitedTopics() {
  const response = await axiosInstance.get('/api/topics/last');
  return response.data;
}

// songs
export async function fetchSongs() {
  const response = await axiosInstance.get('/api/songs');
  return response.data;
}

export async function createSong(song) {
  const response = await axiosInstance.post('/api/songs', song);
  return response.data;
}

export async function getWatchers() {
  const response = await axiosInstance.get('/api/watchers');
  return response.data;
}

export async function deleteWatcher(watcherId) {
  const response = await axiosInstance.delete(`/api/watchers/${watcherId}`);
  return response.data;
}

export async function createWatchers(watchers) {
  const response = await axiosInstance.post('/api/watchers/multiple', { watchers });
  return response.data;
}

export async function searchSymbols(search) {
  const response = await axiosInstance.get('/api/tickers/symbols', { params: { search } });
  return response.data;
}

export async function registerUser(data) {
  const request = {
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName,
    phone: data.phone,
    language: data.language,
    promoCode: data.promoCode,
    deviceId: 'PC',
    platform: 'WEB',
  };

  const response = await axiosInstance.post('/api/users/register', request);

  return response.data;
}

export async function resetPassword(email) {
  const response = await axiosInstance.post('/api/users/reset-password', { email });
  return response.data;
}

export async function getActiveProduct() {
  const response = await axiosInstance.get('/api/subscriptions/active-product');
  return response.data;
}

export async function deleteUser() {
  const response = await axiosInstance.delete('/api/users/me');
  return response.data;
}

export async function changeLanguage(language) {
  const response = await axiosInstance.post('/api/users/change-language', {
    language,
  });

  return response.data;
}

export async function getTopic(id) {
  const response = await axiosInstance.get(`/api/topics/detail/${id}`);
  return response.data;
}

export async function getRoomByRelatedId(roomType, relatedId) {
  const response = await axiosInstance.get('/api/rooms', {
    params: {
      roomType,
      relatedId,
    },
  });
  return response.data;
}

export async function createEmptyMessage(roomId) {
  const response = await axiosInstance.get(`/api/rooms/create-empty-message/${roomId}`);
  return response.data;
}

export async function likeMessage(messageId) {
  const response = await axiosInstance.post(`/api/rooms/like-message/${messageId}`);
  return response.data;
}

function base64ToBlob(base64, mimeType) {
  const byteString = atob(base64.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeType });
}

export async function uploadFileForRoom(fileData, roomId, messageId) {
  const formData = new FormData();

  // Get MIME type and set the file accordingly
  const mimeType = mime.getType(fileData.name);
  if (!mimeType) {
    throw new Error('Invalid file type');
  }

  formData.append('file', fileData.file, fileData.name);
  formData.append('roomId', roomId);
  formData.append('messageId', messageId);

  console.log('formData', formData);

  const response = await axiosInstance.post(
    `/api/rooms/upload-file/${roomId}/${messageId}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  if (response.status !== 200) {
    throw new Error(`Upload failed with status code ${response.status}`);
  }

  return response.data;
}

export async function getFirstPageKey(limit, day) {
  const queryParameters = {
    limit,
    day,
    timezoneOffsetInHours: 0,
  };

  const response = await axiosInstance.get('/api/events/firstPageKey', { params: queryParameters });
  return response.data;
}

export async function getEvents(page, limit, day, firstTime) {
  const timezoneOffestFromUTC = new Date().getTimezoneOffset() / 60;

  console.log(timezoneOffestFromUTC);

  const queryParameters = {
    page,
    limit,
    day,
    firstTime,
    timezoneOffsetInHours: 0,
  };

  const response = await axiosInstance.get('/api/events', { params: queryParameters });

  return response.data;
}

export async function uploadImageForRoom(image, roomId, messageId) {
  const formData = new FormData();

  // Get MIME type and set the file accordingly
  const mimeType = mime.getType(image.name);
  if (!mimeType) {
    throw new Error('Invalid file type');
  }
  const imageBlob = base64ToBlob(image.path, mimeType);

  // base64 encoded image
  formData.append('image', imageBlob, image.name);
  formData.append('roomId', roomId);
  formData.append('messageId', messageId);

  console.log('formData', formData);

  const response = await axiosInstance.post(
    `/api/rooms/upload/${roomId}/${messageId}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  if (response.status !== 200) {
    throw new Error(`Upload failed with status code ${response.status}`);
  }

  return response.data;
}

/// //////////////////////  API CALLS  ///////////////////////////

export async function registerConfirm() {
  const response = await axiosInstance.post('/api/auth/register-confirm');
  return response.data;
}

export async function sendContactEmail(data) {
  const response = await axiosInstance.post('/api/users/sendmail', data);
  return response.data;
}

export async function getUserInfo() {
  const response = await axiosInstance.get('/api/users/me');
  return response.data;
}

export async function getUserSubscription() {
  const response = await axiosInstance.get('/api/subscriptions/active-subscription');
  return response.data;
}

export async function getTerminals() {
  const response = await axiosInstance.get('/api/terminals');
  return response.data;
}

export async function withdraw(data) {
  const response = await axiosInstance.post(
    '/api/transactions/withdraw',
    {
      data,
    },
    { timeout: 120000 },
  );
  return response.data;
}

export async function reSendEmailWithdraw(data) {
  const respons = await axiosInstance.post(
    '/api/transactions/resend',
    {
      data,
    },
    { timeout: 120000 },
  );
  return respons.data;
}

export async function getTransaction(uuid) {
  const response = await axiosInstance.get(`/api/transactions/${uuid}`);
  return response.data;
}

// TODO unused for now on FE
// export async function getATMs(lat, lon) {
//   const response = await axiosInstance.get('/api/atms', {
//     params: { lat, lon },
//   });
//   return response.data;
// }

export async function getAllAtms(filter) {
  // dont send empty params
  if (!filter.serialNumber) filter.serialNumber = undefined;
  if (!filter.status) filter.status = undefined;
  filter.address = filter.address ? encodeURIComponent(filter.address) : undefined;
  filter.name = filter.name ? encodeURI(filter.name) : undefined;
  filter.note = filter.note ? encodeURI(filter.note) : undefined;

  const response = await axiosInstance.get('/api/atms/all', {
    params: filter,
  });

  return response.data;
}

export async function getOperators() {
  const response = await axiosInstance.get('/api/users/operators');
  return response.data;
}

export async function fetchOperatorSettings() {
  const response = await axiosInstance.get('/api/users/operator/settings');
  return response.data;
}

export async function saveOperatorSettings(settings) {
  const response = await axiosInstance.post('/api/users/operator/settings', {
    settings,
  });
  return response.data;
}

export async function activate(active, atmId) {
  const response = await axiosInstance.post('/api/atms/activate', {
    atmId,
    active,
  });
  return response.data;
}

export async function activateAtmByAdmin(active, atmId) {
  const response = await axiosInstance.post('/api/atms/admin/activate', {
    atmId,
    active,
  });
  return response.data;
}

export async function activateOperator(active, operatorId) {
  const response = await axiosInstance.post('/api/admin/operators/activate', {
    operatorId,
    active,
  });
  return response.data;
}

export async function getPopularAtms() {
  const response = await axiosInstance.get('/api/atms/popular');
  return response.data;
}

export async function getDetails(id) {
  const response = await axiosInstance.get(`/api/atms/details/${id}`);
  return response.data;
}

export async function searchAtms(data) {
  const response = await axiosInstance.post('/api/atms/search', data);
  return response.data;
}

export async function getReviews(id) {
  const response = await axiosInstance.get(`/api/reviews/${id}`);
  return response.data;
}

export async function addReview(id, data) {
  const response = await axiosInstance.post(`/api/reviews/${id}`, data);
  return response.data;
}

export async function getReviewReplies(id, reviewId) {
  const response = await axiosInstance.get(`/api/reviews/${id}/replies/${reviewId}`);
  return response.data;
}

export async function addReply(id, reviewId, data) {
  const response = await axiosInstance.post(
    `/api/reviews/${id}/replies/${reviewId}`,
    data,
  );
  return response.data;
}

export async function register(user) {
  return axios.post('/api/auth/register', user);
}

export async function createApplication(operator) {
  return axios.post('/api/applications/create', operator);
}

export async function login(user) {
  return axios.post('/api/auth/login', user);
}

// ADMIN
export async function getUsers(email, status, type, role) {
  const response = await axiosInstance.post('/api/admin/users', {
    params: {
      email, status, role, type,
    },
  });
  return response.data;
}

export async function activateUser(active, userId) {
  return axios.post('/api/admin/activate', {
    userId,
    active,
  });
}

export async function getTransactions(filter) {
  if (!filter.atmSerialNumber) filter.atmSerialNumber = undefined;

  if (!filter.status) filter.status = undefined;
  else filter.status = filter.status.map((s) => s.value).join(',');

  if (!filter.transactionUUID) filter.transactionUUID = undefined;

  filter.atmName = filter.atmName ? encodeURI(filter.atmName) : undefined;
  filter.cryptoAddress = filter.cryptoAddress ? encodeURI(filter.cryptoAddress) : undefined;
  filter.operator = filter.operator ? encodeURI(filter.operator) : undefined;
  filter.user = filter.user ? encodeURI(filter.user) : undefined;
  const response = await axiosInstance.get(
    '/api/transactions',
    { params: filter },
  );
  return response.data;
}

export async function getApplications(name, status) {
  const response = await axiosInstance.post('/api/applications', {
    params: { name, status },
  });
  return response.data;
}

export async function approve(id, email) {
  return axios.post(`/api/applications/approve/${id}`, { email });
}

export async function reject(id) {
  return axios.post(`/api/applications/reject/${id}`);
}

export async function getCurrencyConfiguration(currency) {
  const response = await axiosInstance.get(`/api/configuration/currency/${currency}`);
  return response.data;
}

export async function editNote(atmId, note) {
  const response = await axiosInstance.post('/api/atms/admin/note', {
    atmId,
    note,
  });
  return response.data;
}

export async function validateUnicOperator(operatorName) {
  const data = {
    operatorName,
  };
  const response = await axiosInstance.post('/api/users/operator/validate', data, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return response.data;
}
export async function uploadImgs(form) {
  const response = await axiosInstance.post('/api/atms/operator/upload', form, {
    headers: {
      type: 'multipart/form-data',
    },
  });
  return response.data;
}

export async function getImgsForAtm(atmId) {
  const response = await axiosInstance.get(`/api/atms/getImgAtm/${atmId}`);
  return response.data;
}

export async function deleteImg(_id) {
  const response = await axiosInstance.delete('/api/atms/operator/deleteimg', {
    params: { _id },
  });
  return response.data;
}

export async function importOperator(data) {
  const response = await axiosInstance.post('/api/users/operator/passive/import', data);
  return response.data;
}

// UPVOTES
export async function getUpvotes(atmId) {
  const response = await axiosInstance.get(`/api/upvotes/${atmId}`);
  return response.data;
}

export async function getVoted(atmId) {
  const response = await axiosInstance.get(`/api/upvotes/${atmId}/voted`);
  return response.data;
}

export async function vote(atmId) {
  const response = await axiosInstance.post(`/api/upvotes/vote/${atmId}`);
  return response.data;
}

// SUBSCRIPTIONS
export async function subscribe(atmId, email) {
  const response = await axiosInstance.post(`/api/subscriptions/${atmId}`, { email });
  return response.data;
}

export async function isSubscribed(atmId) {
  const response = await axiosInstance.get(`/api/subscriptions/${atmId}/subscribed`);
  return response.data;
}

// Support

// export async function getJiraCustomerRequest(data) {
//   const respons = await axiosInstance.post('/api/support/getcustomerrequest', data);
//   return respons.data;
// }

// Prizes

export async function claimPrize(atmId) {
  const response = await axiosInstance.post('/api/rewards/claim', { atmId });
  return response.data;
}

export async function getPrize(id) {
  const response = await axiosInstance.get(`/api/rewards/${id}`);
  return response.data;
}

export async function getClaims(status, email) {
  // dont sent empty params
  if (!status) status = undefined;
  if (!email) email = undefined;

  const response = await axiosInstance.get('/api/rewards', { params: { status, email } });
  return response.data;
}

export async function confirmClaim(code) {
  const response = await axiosInstance.get(`/api/rewards/confirm/${code}`);
  return response.data;
}
