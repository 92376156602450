// utils/gtm.js
export const pushToDataLayer = (event, properties = {}) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    console.log('pushing to dataLayer', event, properties);
    window.dataLayer.push({
      event,
      ...properties,
      timestamp: new Date().toISOString(),
    });
  } else {
    console.log('window.dataLayer not found');
  }
};

// Example event categories
export const GTM_EVENTS = {
  BUTTON_CLICK: 'button_click',
  FORM_SUBMIT: 'form_submit',
  AUTH_ACTION: 'auth_action',
  FORM_INPUT: 'form_input',
  PAGE_VIEW: 'page_view',
};

export const trackButtonClick = (buttonName, additionalProps = {}) => {
  pushToDataLayer(GTM_EVENTS.BUTTON_CLICK, {
    button_name: buttonName,
    button_type: 'click',
    page_location: window.location.pathname,
    ...additionalProps,
  });
};

export const trackFormSubmit = (formName, status, additionalProps = {}) => {
  pushToDataLayer(GTM_EVENTS.FORM_SUBMIT, {
    form_name: formName,
    submission_status: status,
    page_location: window.location.pathname,
    ...additionalProps,
  });
};

export const trackAuthAction = (actionType, method, status, additionalProps = {}) => {
  pushToDataLayer(GTM_EVENTS.AUTH_ACTION, {
    action_type: actionType,
    auth_method: method,
    auth_status: status,
    page_location: window.location.pathname,
    ...additionalProps,
  });
};

export const trackFormInput = (inputName, properties = {}) => {
  pushToDataLayer('form_input', {
    input_name: inputName,
    input_type: 'change',
    page_location: window.location.pathname,
    ...properties,
  });
};
