<template>
  <div
    class="p-3 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full max-w-md"
  >
    <div class="relative p-4 lg:p-7 rounded-xl bg-primary-medium text-center">
      <div class="my-3 lg:my-5">
        <img class="mx-auto" src="../images/logo.svg" alt="" />
        <div class="my-4 text-lg font-bold">{{ $t('login.welcomeBack') }}</div>
        <div class="text-left">
          <div class="mb-2.5">
            <label class="mb-1 block text-sm text-primary-muted">{{
              $t('login.emailLabel')
            }}</label>
            <input
              v-model="email"
              type="text"
              class="form-input w-full h-10 px-4 bg-[#E6E7E8] border border-white text-primary-dark rounded-lg text-sm focus:ring-1 focus:ring-primary-muted focus:border-primary-muted placeholder:text-primary-muted"
              :placeholder="$t('login.emailPlaceholder')"
            />
            <div
              class="input-errors"
              v-for="error of v$.email.$errors"
              :key="error.$uid"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
          <div class="mb-2.5">
            <label class="mb-1 block text-sm text-primary-muted">{{
              $t('login.passwordLabel')
            }}</label>
            <input
              v-model="password"
              type="password"
              class="form-input w-full h-10 px-4 bg-[#E6E7E8] border border-white text-primary-dark rounded-lg text-sm focus:ring-1 focus:ring-primary-muted focus:border-primary-muted placeholder:text-primary-muted"
              :placeholder="$t('login.passwordPlaceholder')"
            />
            <div
              class="input-errors"
              v-for="error of v$.password.$errors"
              :key="error.$uid"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
          <div
            class="mt-3 mb-5 lg:mb-7 flex flex-wrap gap-1 justify-between text-sm"
          >
            <label class="flex items-center gap-2">
              <input
                v-model="remember"
                type="checkbox"
                class="text-primary-main focus:ring-offset-0 focus:ring-0 active:brightness-95"
              />
              <span>{{ $t('login.rememberMe') }}</span>
            </label>
            <router-link
              class="text-primary-main hover:underline"
              to="/forgot-password"
            >
              {{ $t('login.forgotPassword') }}
            </router-link>
          </div>
        </div>
        <div class="w-full mt-4 flex flex-col gap-4 items-center font-semibold">
          <button
            class="w-full px-4 py-1.5 bg-primary-main rounded-lg hover:brightness-110 active:brightness-95"
            @click="onSubmit()"
          >
            {{ $t('login.signInButton') }}
          </button>
        </div>
        <div class="my-5 flex flex-col gap-3 items-center">
          <button
            class="w-full px-4 py-1.5 bg-primary-main rounded-lg hover:brightness-110 active:brightness-95 google btn-outline"
            @click="googleSignIn"
          >
            <div class="flex items-center justify-center gap-2">
              <img
                class="icon-inline"
                src="../images/google-icon.svg"
                alt="Google"
              />
              <span>{{ $t('login.signInWithGoogle') }}</span>
            </div>
          </button>
          <button
            class="w-full px-4 py-1.5 bg-primary-main rounded-lg hover:brightness-110 active:brightness-95 apple btn-outline"
            @click="appleSignIn"
          >
            <div class="flex items-center justify-center gap-2">
              <img
                class="icon-inline white"
                src="../images/apple-icon.svg"
                alt="Apple"
              />
              <span>{{ $t('login.signInWithApple') }}</span>
            </div>
          </button>
        </div>
        <div class="mt-5 text-sm">
          <span class="text-primary-muted"> {{$t('login.noAccount') }}</span>
          <router-link class="text-primary-main hover:underline" to="/register">
            {{ " " +  $t('login.signUpHere') }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import { useToast } from 'vue-toastification';
import * as api from '../api';
import * as utils from '../utils/utils';
import { trackButtonClick, trackFormSubmit, trackAuthAction } from '../utils/gtm';

export default {
  name: 'LoginScreen',
  setup() {
    return {
      v$: useVuelidate(),
      toast: useToast(),
    };
  },
  data() {
    return {
      email: null,
      password: null,
      remember: false,
    };
  },
  validations: {
    email: {
      email,
      required,
    },
    password: {
      required,
    },
  },
  mounted() {
    this.loadEmailFromStorage();
  },
  methods: {
    loadEmailFromStorage() {
      const savedEmail = localStorage.getItem('email');
      if (savedEmail) {
        this.email = savedEmail;
        this.remember = true;
      }
    },
    async register() {
      trackButtonClick('register_redirect', {
        destination: 'register',
      });
      this.$router.push({ name: 'register' });
    },
    async googleSignIn() {
      trackButtonClick('google_sign_in');
      try {
        await utils.googleSignIn(this.$store, this.$router);
        trackAuthAction('login', 'google', 'success');
        this.$router.push({ name: 'dashboard' });
      } catch (error) {
        trackAuthAction('login', 'google', 'error', {
          error_type: error.code || 'unknown',
        });
        throw error;
      }
    },
    async appleSignIn() {
      trackButtonClick('apple_sign_in');
      try {
        await utils.appleSignIn(this.$store, this.$router);
        trackAuthAction('login', 'apple', 'success');
        this.$router.push({ name: 'dashboard' });
      } catch (error) {
        trackAuthAction('login', 'apple', 'error', {
          error_type: error.code || 'unknown',
        });
        throw error;
      }
    },
    async onSubmit() {
      console.log('Login');
      trackButtonClick('email_sign_in_submit');

      this.v$.$validate();
      if (this.v$.$invalid) {
        trackFormSubmit('login_form', 'validation_error', {
          validation_errors: Object.keys(this.v$.$errors).join(','),
        });
        this.submitStatus = 'ERROR';
      } else {
        const user = {
          email: this.email,
          password: this.password,
          remember: this.remember,
        };

        if (this.remember) {
          localStorage.setItem('email', this.email);
        } else {
          localStorage.removeItem('email');
        }

        try {
          await utils.emailSignIn(this.$store, this.$router, user);
          trackAuthAction('log_in', 'email', 'success', {
            remember_me: this.remember,
          });
          this.complete = true;
          this.$router.push({ name: 'dashboard' });
        } catch (e) {
          const errorDetails = {
            error_type: e.code || 'unknown',
            http_status: e.response?.status,
            error_message: e.response?.data?.message,
          };

          trackAuthAction('log_in', 'email', 'error', errorDetails);

          if (e.response && e.response.status === 401) {
            console.log(e);
            this.toast.error(
              this.$t(`errors.${e.response.data.message}.message`),
            );
          } else {
            console.error(e);
            this.toast.error(this.$t(`errors.${e.code}`));
          }
        }
      }
    },
  },
};
</script>

<style>
.input-errors {
  color: red;
}
.apple {
  background-color: #000 !important;
}
.google {
  background-color: #fff !important;
  color: #000 !important;
}
.btn-outline {
  border: 1px solid #699ce2;
  background-color: #fff !important;
  color: rgb(83 80 130 / var(--tw-bg-opacity));
}
</style>
