<template>
  <aside class="w-full lg:max-w-[184px] hidden lg:flex flex-col flex-shrink-0 gap-3 lg:gap-5 bg-primary-medium lg:bg-transparent rounded-lg lg:rounded-none p-4 lg:p-0" :class="{ '!flex': mobileNav }">
    <OnClickOutside @trigger="closeNav">
      <ul class="flex flex-col gap-3">
        <transition name="fade" mode="out-in">
          <li v-if="guru">
            <router-link
              :to="{
                name: 'room',
                params: { id: guru._id },
                query: { type: 'guru' }
              }"
              class="group flex items-center gap-3 active:brightness-95 px-1 "
              active-class="active-link"
              exact-active-class="exact-active-link"
              @click.native="closeNav"
            >
              <span
                :class="[
                  'flex items-center justify-center circle-xl cursor-pointer group-hover:bg-primary-main group-hover:text-white',
                  $route.query.type === 'guru' ? 'bg-primary-main text-white' : 'bg-primary-light lg:bg-primary-medium text-white lg:text-primary-muted'
                ]"
              >
                <div class="w-10 lg:w-12 flex-shrink-0">
                  <div class="relative w-10 h-10 lg:w-12 lg:h-12 overflow-hidden flex items-center justify-center">
                    <img src="../images/circle-avatar.png" width="45px" alt="">
                  </div>
                </div>
              </span>
              <div>
                <span>{{ $t('sidebar.guruName') }}</span>
                <div class="text-sm text-primary-muted line-clamp-2">{{ $t('sidebar.guruTitle') }}</div>
              </div>
            </router-link>
          </li>
        </transition>
        <li>
          <router-link
            :to="{ name: 'dashboard' }"
            class="group flex items-center gap-3 active:brightness-95 px-2"
            active-class="active-link"
            exact-active-class="exact-active-link"
            @click.native="closeNav"
          >
            <span
              :class="[
                'w-8 h-8 lg:w-10 lg:h-10 flex items-center justify-center border-0 rounded-lg cursor-pointer group-hover:bg-primary-main group-hover:text-white',
                $route.name === 'dashboard' ? 'bg-primary-main text-white' : 'bg-primary-light lg:bg-primary-medium text-white lg:text-primary-muted'
              ]"
            >
              <i class="icon-home text-xl lg:text-2xl"></i>
            </span>
            <span>{{ $t('sidebar.dashboard') }}</span>
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'topics' }"
            class="group flex items-center gap-3 active:brightness-95 px-2"
            active-class="active-link"
            exact-active-class="exact-active-link"
            @click.native="closeNav"
          >
            <span
              :class="[
                'w-8 h-8 lg:w-10 lg:h-10 flex items-center justify-center border-0 rounded-lg cursor-pointer group-hover:bg-primary-main group-hover:text-white',
                $route.name === 'topics' ? 'bg-primary-main text-white' : 'bg-primary-light lg:bg-primary-medium text-white lg:text-primary-muted'
              ]"
            >
              <i class="icon-idea text-xl lg:text-2xl"></i>
            </span>
            <span>{{ $t('sidebar.topics') }}</span>
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'profile' }"
            class="group flex items-center gap-3 active:brightness-95 px-2"
            active-class="active-link"
            exact-active-class="exact-active-link"
            @click.native="closeNav"
          >
            <span
              :class="[
                'w-8 h-8 lg:w-10 lg:h-10 flex items-center justify-center border-0 rounded-lg cursor-pointer group-hover:bg-primary-main group-hover:text-white',
                $route.name === 'profile' ? 'bg-primary-main text-white' : 'bg-primary-light lg:bg-primary-medium text-white lg:text-primary-muted'
              ]"
            >
              <i class="icon-user text-xl lg:text-2xl"></i>
            </span>
            <span>{{ $t('sidebar.profile') }}</span>
          </router-link>
        </li>
        <!-- <li>
          <router-link
            :to="{ name: 'shop' }"
            class="group flex items-center gap-3 active:brightness-95 px-2"
            active-class="active-link"
            exact-active-class="exact-active-link"
            @click.native="closeNav"
          >
            <span
              :class="[
                'w-8 h-8 lg:w-10 lg:h-10 flex items-center justify-center border-0 rounded-lg cursor-pointer group-hover:bg-primary-main group-hover:text-white',
                $route.name === 'shop' ? 'bg-primary-main text-white' : 'bg-primary-light lg:bg-primary-medium text-white lg:text-primary-muted'
              ]"
            >
              <i class="icon-user text-xl lg:text-2xl"></i>
            </span>
            <span>{{ $t('sidebar.shop') }}</span>
          </router-link>
        </li> -->
        <li class="lg:hidden">
          <a class="group flex items-center gap-3 active:brightness-95 px-2" href="#" @click="logout">
            <span
              :class="[
                'w-8 h-8 lg:w-10 lg:h-10 flex items-center justify-center border-0 rounded-lg cursor-pointer group-hover:bg-primary-main group-hover:text-white',
                $route.name === 'logout' ? 'bg-primary-main text-white' : 'bg-primary-light lg:bg-primary-medium text-white lg:text-primary-muted'
              ]"
            >
              <i class="icon-logout text-2xl"></i>
            </span>
            <span>{{ $t('sidebar.logout') }}</span>
          </a>
        </li>
      </ul>

      <ul class="flex flex-col gap-3 mt-3">
        <h3>{{ $t('sidebar.financePlugin') }}</h3>

        <li>
          <router-link
            :to="{ name: 'news' }"
            class="group flex items-center gap-3 active:brightness-95 px-2"
            active-class="active-link"
            exact-active-class="exact-active-link"
            @click.native="closeNav"
          >
            <span
              :class="[
                'w-8 h-8 lg:w-10 lg:h-10 flex items-center justify-center border-0 rounded-lg cursor-pointer group-hover:bg-primary-main group-hover:text-white',
                $route.name === 'news' ? 'bg-primary-main text-white' : 'bg-primary-light lg:bg-primary-medium text-white lg:text-primary-muted'
              ]"
            >
              <i class="icon-list-check text-xl lg:text-2xl"></i>
            </span>
            <span>{{ $t('sidebar.news') }}</span>
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'signals' }"
            class="group flex items-center gap-3 active:brightness-95 px-2"
            active-class="active-link"
            exact-active-class="exact-active-link"
            @click.native="closeNav"
          >
            <span
              :class="[
                'w-8 h-8 lg:w-10 lg:h-10 flex items-center justify-center border-0 rounded-lg cursor-pointer group-hover:bg-primary-main group-hover:text-white',
                $route.name === 'signals' ? 'bg-primary-main text-white' : 'bg-primary-light lg:bg-primary-medium text-white lg:text-primary-muted'
              ]"
            >
              <i class="fa-solid fa-arrow-trend-up text-xl lg:text-2xl"></i>
            </span>
            <span>{{ $t('sidebar.signals') }}</span>
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'calendar' }"
            class="group flex items-center gap-3 active:brightness-95 px-2"
            active-class="active-link"
            exact-active-class="exact-active-link"
            @click.native="closeNav"
          >
            <span
              :class="[
                'w-8 h-8 lg:w-10 lg:h-10 flex items-center justify-center border-0 rounded-lg cursor-pointer group-hover:bg-primary-main group-hover:text-white',
                $route.name === 'calendar' ? 'bg-primary-main text-white' : 'bg-primary-light lg:bg-primary-medium text-white lg:text-primary-muted'
              ]"
            >
              <i class="text-xl lg:text-2xl fa-regular fa-calendar-days"></i>
            </span>
            <span>{{ $t('sidebar.calendar') }}</span>
          </router-link>
        </li>
      </ul>
    </OnClickOutside>
  </aside>
</template>

<script>
import { mapState } from 'vuex';
import { OnClickOutside } from '@vueuse/components';
import useEventsBus from './EventBus.vue';

const { bus } = useEventsBus();

export default {
  data() {
    return {
      activeItem: '',
      mobileNav: false,
    };
  },
  components: {
    OnClickOutside,
  },
  computed: {
    ...mapState(['guru']),
  },
  created() {
    this.emitter.on('mobileNav', (evt) => {
      this.mobileNav = evt.eventContent;
    });
  },
  methods: {
    closeNav() {
      if (this.mobileNav) {
        setTimeout(() => {
          this.mobileNav = false;
          this.emitter.emit('mobileNav', { eventContent: this.mobileNav });
        }, 100); // Delay of 100ms
      }
    },
    setActiveItem(item) {
      this.activeItem = item;
    },
    async logout() {
      console.log('logout');
      await this.$store.dispatch('logOut');
      this.$router.push({ name: 'login' });
      this.hideMenu();
    },
  },
};
</script>

<style>
.active-link {
  background-color: var(--color-primary-main);
  color: white;
}

.exact-active-link {
  background-color: var(--color-primary-main);
  color: white;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.circle-xl {
  border-radius: 25px;
}

.px-1 {
  padding-left: 0.35rem;
  padding-right: 0.35rem;
}

</style>
