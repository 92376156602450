<template>
  <div class="flex-grow">
    <h1 class="py-1 my-px font-bold">{{ $t('profile.title') }}</h1>
    <div class="flex flex-wrap items-center justify-between gap-2" v-if="userInfo">
      <div class="flex items-center gap-4">
        <div class="relative w-16 h-16 flex-shrink-0 bg-primary-light rounded-xl overflow-hidden">
          <img class="w-full h-full block object-cover" src="../tmp/avatar.png" srcset="../tmp/avatar.png 1x, ../tmp/avatar@2x.png 2x" alt="">
        </div>
        <div>
          <div class="font-bold">{{ userInfo.firstName }} {{ userInfo.lastName }}</div>
        </div>
      </div>
      <div class="flex gap-2 text-sm font-semibold">
        <a class="px-4 py-1.5 border border-primary-main bg-primary-main rounded-lg hover:brightness-110 active:brightness-95" href="#" @click.prevent="logout">{{ $t('profile.signOut') }}</a>
        <a class="px-4 py-1.5 border border-primary-light bg-primary-light rounded-lg hover:brightness-110 active:brightness-95" href="#" @click.prevent="openDeleteAccountModal">{{ $t('profile.deleteAccount') }}</a>
      </div>
    </div>
    <div class="my-3 lg:my-5" v-if="userInfo">
      <div class="py-3.5 border-b border-b-primary-light flex items-center justify-between">
        <div class="text-primary-muted">{{ $t('profile.email') }}</div>
        <div class="text-right">{{ userInfo.email }}</div>
      </div>
      <div class="py-3.5 border-b border-b-primary-light flex items-center justify-between">
        <div class="text-primary-muted">{{ $t('profile.language') }}</div>
        <div class="text-right">
          <div class="flex items-center gap-2">
            <span>{{getLanguage(userInfo.language) }}</span>
          </div>
        </div>
      </div>
      <div class="py-3.5 border-b border-b-primary-light flex items-center justify-between">
        <div class="text-primary-muted">{{ $t('profile.subscription') }}</div>
        <div class="-my-1.5 text-right leading-4" v-if="!userSubscription">
          <div>{{ $t('profile.noSubscription') }}</div>
          <a class="text-sm text-primary-main hover:underline" href="">{{ $t('profile.showSubscriptionOptions') }}</a>
        </div>
        <div class="-my-1.5 text-right leading-4" v-if="userSubscription && activeProduct">
          <div>{{ activeProduct.name }}</div>
          <div class="text-sm text-primary-main">{{ formatDate(userSubscription.createdAt) }} - {{ formatDate(userSubscription.expiresDate) }}</div>
          <div class="text-sm text-primary-muted">
            {{ $t('profile.cannotBeManaged') }}
          </div>
        </div>
      </div>
      <div class="border-b border-b-primary-light">
        <div class="group py-3.5 flex items-center justify-between cursor-pointer" :class="{ 'text-primary-main' : passwordDropdown }" @click.prevent="togglePasswordDropdown">
          <span>{{ $t('profile.changePassword') }}</span>
          <i class="icon-angle-down text-2xl text-primary-muted group-hover:text-white" :class="{ 'rotate-180' : passwordDropdown, '!text-primary-main' : passwordDropdown }"></i>
        </div>
        <transition name="slide-fade">
          <div v-if="passwordDropdown" class="mb-3 text-sm text-primary-muted">
            <div class="max-w-64">
              <div class="mb-2.5">
                <label class="mb-1 block text-sm text-primary-muted">{{ $t('profile.oldPassword') }}</label>
                <input
                  type="password"
                  class="form-input w-full h-10 px-4 bg-[#E6E7E8] border border-white text-primary-dark rounded-lg text-sm focus:ring-1 focus:ring-primary-muted focus:border-primary-muted placeholder:text-primary-muted"
                  :placeholder="$t('profile.oldPasswordPlaceholder')"
                  v-model="password.old"
                >
                <div class="input-errors" v-for="error of v$.password.old.$errors" :key="error.$uid">
                  <div class="error-msg">{{ $t(error.$message) }}</div>
                </div>
              </div>
              <div class="mb-2.5">
                <label class="mb-1 block text-sm text-primary-muted">{{ $t('profile.newPassword') }}</label>
                <input
                  type="password"
                  class="form-input w-full h-10 px-4 bg-[#E6E7E8] border border-white text-primary-dark rounded-lg text-sm focus:ring-1 focus:ring-primary-muted focus:border-primary-muted placeholder:text-primary-muted"
                  :placeholder="$t('profile.newPasswordPlaceholder')"
                  v-model="password.new"
                >
                <div class="input-errors" v-for="error of v$.password.new.$errors" :key="error.$uid">
                  <div class="error-msg">{{ $t(error.$message) }}</div>
                </div>
              </div>
              <div class="mb-2.5">
                <label class="mb-1 block text-sm text-primary-muted">{{ $t('profile.confirmPassword') }}</label>
                <input
                  type="password"
                  class="form-input w-full h-10 px-4 bg-[#E6E7E8] border border-white text-primary-dark rounded-lg text-sm focus:ring-1 focus:ring-primary-muted focus:border-primary-muted placeholder:text-primary-muted"
                  :placeholder="$t('profile.confirmPasswordPlaceholder')"
                  v-model="password.confirm"
                >
                <div class="input-errors" v-for="error of v$.password.confirm.$errors" :key="error.$uid">
                  <div class="error-msg">{{ $t(error.$message) }}</div>
                </div>
              </div>
              <button class="mt-2.5 h-10 px-7 bg-primary-main inline-flex items-center justify-center rounded-lg text-white font-semibold hover:brightness-110 active:brightness-95" @click="changePassword">{{ $t('profile.changePasswordButton') }}</button>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <DeleteAccountModal
      v-if="showDeleteAccountModal"
      :deleteAccount="deleteAccount"
      @close="closeModal"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import {
  required, minLength, helpers,
} from '@vuelidate/validators';
import { useToast } from 'vue-toastification';
import * as api from '../api';
import * as utils from '../utils/utils';
import DeleteAccountModal from '../components/modal/DeleteAccountModal.vue';

export default {
  name: 'ProfileScreen',
  components: {
    DeleteAccountModal,
  },
  setup() {
    return {
      v$: useVuelidate(),
      toast: useToast(),
    };
  },
  data() {
    return {
      activeProduct: null,
      passwordDropdown: false,
      showDeleteAccountModal: false,
      password: {
        old: null,
        new: null,
        confirm: null,
      },
      languages: [
        { code: 'en', name: 'English', flag: 'GB' },
        { code: 'sk', name: 'Slovenský', flag: 'SK' },
        { code: 'de', name: 'Deutsch', flag: 'DE' },
        { code: 'es', name: 'Español', flag: 'ES' },
        { code: 'cs', name: 'Česky', flag: 'CZ' },
        { code: 'sr', name: 'Srpski', flag: 'RS' },
      ],
    };
  },
  validations() {
    return {
      password: {
        old: { required: helpers.withMessage('errors.oldPasswordRequired', required) },
        new: {
          required: helpers.withMessage('errors.newPasswordRequired', required),
          minLength: helpers.withMessage('errors.passwordMinLength', minLength(8)),
        },
        confirm: {
          required: helpers.withMessage('errors.confirmPasswordRequired', required),
          sameAsNew: helpers.withMessage('errors.passwordsDoNotMatch', (value) => value === this.password.new),
        },
      },
    };
  },
  async created() {
    this.activeProduct = await api.getActiveProduct();
  },
  computed: {
    ...mapState(['userInfo']),
    ...mapState(['userSubscription']),
  },
  methods: {
    getLanguage(code) {
      return this.languages.find((lang) => lang.code === code).name;
    },
    async logout() {
      console.log('logout');
      await this.$store.dispatch('logOut');
      this.$router.push({ name: 'login' });
      this.hideMenu();
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
    togglePasswordDropdown() {
      this.passwordDropdown = !this.passwordDropdown;
      if (!this.passwordDropdown) {
        this.resetPasswordForm();
      }
    },
    resetPasswordForm() {
      this.password = {
        old: null,
        new: null,
        confirm: null,
      };
      this.v$.password.$reset();
    },
    async changePassword() {
      console.log('changing password');
      this.v$.$validate();
      if (!this.v$.$error) {
        try {
          await utils.changePassword(this.$store, this.password);
        } catch (e) {
          this.toast.error(this.$t(`errors.${e.code}`));
          this.passwordDropdown = false;
          this.resetPasswordForm();
          return;
        }

        this.toast.success(this.$t('messages.PASSWORD_CHANGED'));
        this.passwordDropdown = false;
        this.resetPasswordForm();
      }
    },
    openDeleteAccountModal() {
      console.log('open delete account modal');
      this.showDeleteAccountModal = true;
    },
    closeModal() {
      console.log('close modal');
      this.showDeleteAccountModal = false;
    },
    async deleteAccount() {
      console.log('deleting account');
      try {
        await api.deleteUser();
        this.$router.push({ name: 'login' });
      } catch (e) {
        this.toast.error(this.$t(`errors.${e.code}`));
        return;
      }
      this.$router.push({ name: 'login' });
    },
  },
};
</script>

<style>
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 0.4s ease-out;
}
.slide-fade-enter-from, .slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
.slide-fade-enter-to, .slide-fade-leave-from {
  transform: translateY(0);
  opacity: 1;
}
</style>
